<template>
    <v-list>
        <!--Bulk Edit-->
        <v-list-item
            v-if="bulkActions === false"
            @click='passBulkActions'
        >
            <v-icon left small>mdi-pencil</v-icon>
            <v-list-item-content>Bulk Edit</v-list-item-content>
        </v-list-item>
        <!--Create Work Orders-->
        <v-list-item
            v-if="bulkActions === true"
            :disabled="selected_sales_orders.length === 0"
            @click="passBulkWorkOrders"
        >
            <v-icon left small :disabled="selected_sales_orders.length === 0">mdi-clipboard</v-icon>
            <v-list-item-content>Create Work Orders</v-list-item-content>
        </v-list-item>

        <!-- Create Invoices -->
        <v-list-item v-if="bulkActions" :disabled="!selected_sales_orders.length" @click="createBulkInvoices">
            <v-icon left small>mdi-invoice</v-icon>
            <v-list-item-content>Create Xero Invoices</v-list-item-content>
        </v-list-item>

        <!-- Sync Invoices -->
        <v-list-item v-if="bulkActions" :disabled="!selected_sales_orders.length" @click="syncXeroInvoics">
            <v-icon left small>mdi-refresh</v-icon>
            <v-list-item-content>Sync Xero Invoices</v-list-item-content>
        </v-list-item>

        <!--Close Bulk Actions-->
        <v-list-item
            v-if="bulkActions === true"
            @click="passCloseActions"
        >
            <v-icon left small>mdi-close</v-icon>
            <v-list-item-content>Close</v-list-item-content>
        </v-list-item>

        <!-- Loading Modal -->
        <v-dialog v-model="loading" persistent width="300">
            <v-card>
                <v-card-title class="text-h5">Processing...</v-card-title>
                <v-card-text>
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-list>
</template>

<script>
import axios from "axios";
export default {
    name: 'SalesOrdersTableMoreActionsBtn',
    data() {
        return {
            loading: false
        }
    },
    props: ['bulkActions', 'selected_sales_orders'],
    methods: {
        passBulkActions()
        {
            this.$emit('changeBulkActions', true)
        },
        passCloseActions(){
            this.$emit('closeBulkActions', false, [])
        },
        passBulkWorkOrders(){
            this.$emit('triggerBulkWorkOrders')
        },
        //
        async createBulkInvoices() {
            if (!this.selected_sales_orders.length > 0) {
                return alert("No sales orders selected");
            }

            try {
                this.loading = true;
                const response = await axios.post('https://us-central1-vue-order-fulfillment.cloudfunctions.net/createBulkXeroInvoices', {
                    salesOrders: this.selected_sales_orders
                })
                console.log('Invoices Created:', response.data);
                this.passCloseActions(); // Close bulk actions and reset selections
            } catch (error) {
                console.error('Failed to create invoices:', error);
            } finally {
                this.loading = false;
            }
        },
        //
        async syncXeroInvoics() {
            if (!this.selected_sales_orders.length > 0) {
                return alert("No sales orders selected");
            }

            try {
                this.loading = true;
                const response = await axios.post('https://us-central1-vue-order-fulfillment.cloudfunctions.net/syncXeroInvoiceIDs', {
                    salesOrders: this.selected_sales_orders
                })
                console.log('Invoices synced:', response.data);
                this.passCloseActions(); // Close bulk actions and reset selections
            } catch (error) {
                console.error('Failed to sync invoices:', error);
            } finally {
                this.loading = false;
            }
        }
    }
}
</script>
